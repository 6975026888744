<template>
  <v-app>
    <v-main class="login_background">
      <card-login title="Alterar a senha" card_color="white">
        <template v-slot:form>
          <form-reset :formData.sync="user" ref="form"></form-reset>
        </template>
        <template v-slot:control>
          <v-row>
            <v-col>
              <v-btn text color="primary" to="/login">Voltar</v-btn>
            </v-col>
            <v-col>
              <v-btn
                :loading="loading"
                block
                color="primary"
                @click="clickSubmit"
                >Salvar</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </card-login>
    </v-main>
  </v-app>
</template>

<script>
import http from "axios";
export default {
  name: "Password",
  components: {
    CardLogin: () => import("@/_auth/components/containers/_auth_card_01.vue"),
    FormReset: () => import("@/_auth/components/forms/Reset.vue")
  },
  data() {
    return {
      user: {
        password: null,
        password_confirmation: null
      },
      loading: false,
      timeout: 1500
    };
  },
  methods: {
    clickSubmit() {
      this.loading = true;

      setTimeout(() => {
        if (this.$refs.form.validateForm()) {
          this.submitRecover().then(() => {
            this.loading = false;
            this.$router.replace("/login");
          });
        } else {
          this.loading = false;
        }
      }, this.timeout);
    },
    submitRecover() {
      return http
        .put(
          "/api/auth/password",
          {
            password: this.user.password,
            password_confirmation: this.user.password_confirmation
          },
          {
            headers: {
              "access-token": this.$route.query["access-token"],
              client: this.$route.query["client"],
              expiry: this.$route.query["expiry"],
              uid: this.$route.query["uid"],
              "token-type": this.$route.query["token-type"]
            }
          }
        )
        .then(data => {
          this.$sweetAlerts.toast1("success", "Sua senha foi alterada!");
          return this.$auth
            .login({
              params: {
                email: data.data.data.email,
                password: this.user.password
              }
            })

            .then(response => {
              this.$sweetAlerts.toast1("success", "Seja bem vindo!");
            })

            .catch(error => {
              console.log("Erro na tentativa de fazer login..", error);
              this.$sweetAlerts.toast1("error", "Acesso negado!");
            });
        })
        .catch(error => {
          console.log("Erro ao tentar mudar a senha.", error);
        });
    }
  }
};
</script>

<style>
.login_background {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background: url('../../assets/background_login.png');
}
</style>